import { useMachineContext } from "@/context/machine-context";
import { useTimeSelection } from "@/store/useTimeSelection";
import { Box, Grid, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { MachineArray } from "../api/useGetLandingData";

const CategoryColor = {
  Disconnected: { textColor: "#000", bgColor: "#eeeeee" },
  Producing: { textColor: "#fff", bgColor: "#0ab126" },
  "Not Producing": { textColor: "#fff", bgColor: "#ff7a0d" },
  Line: { textColor: "#fff", bgColor: "#1a98c9" },
  "Setup PreProd": { textColor: "#fff", bgColor: "#B277E2" },
  Maintenance: { textColor: "#fff", bgColor: "#8a8a8a" },
};

export const MachineCard = (props: MachineArray) => {
  const {
    machine,
    category,
    state,
    oee,
    machineType,
    machineLogic,
    factory,
    hasBatch,
    hasSpeed,
  } = props;

  const { setMachine } = useMachineContext();
  const { setTimeSelection, timeSelection } = useTimeSelection();
  const navigate = useNavigate();
  return (
    <Grid
      item
      xs={1}
      sm={1}
      md={1}
      component={motion.div}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      whileHover={{ scale: 1.05 }}
    >
      <Stack
        direction="column"
        sx={{ overflow: "hidden", cursor: "pointer" }}
        borderRadius={1}
        component={Box}
        role="button"
        tabIndex={0}
        onClick={() => {
          setMachine({
            machine,
            machineType,
            machineLogic,
            hasBatch,
            hasSpeed,
          });
          timeSelection === "Batch" && hasBatch === false
            ? setTimeSelection("Shift")
            : null;
          navigate("/machine-detail");
        }}
      >
        <Box
          sx={{
            minHeight: "2.5rem",
            padding: ".5rem",
            transition: "all 0.2s ease-in-out",
            opacity: timeSelection === "Batch" ? (hasBatch ? "1" : "0.4") : "1",
            backgroundColor: CategoryColor[category].bgColor,
            color: CategoryColor[category].textColor,
            fontWeight: "500",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              fontWeight: "bold",
            }}
          >
            {state}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              {machineType === "cyclic" ? "OEE " : "POEE "}
              {oee.toFixed(2).replace(/[.,]00$/, "")}
              <span dangerouslySetInnerHTML={{ __html: "&percnt;" }} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            minHeight: "2.5rem",
            padding: ".5rem",
            backgroundColor: "#444D5A",
            opacity: timeSelection === "Batch" ? (hasBatch ? "1" : "0.4") : "1",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {factory}
            </Box>
            <Box sx={{ fontWeight: "bold" }}>{machine}</Box>
          </Box>
        </Box>
      </Stack>
    </Grid>
  );
};
